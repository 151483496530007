require("es6-promise").polyfill();
import "nodelist-foreach-polyfill";
import "slick-carousel"
import $ from 'jquery'

window.addEventListener("DOMContentLoaded", () => {
    $('.content_slider_wrapper').slick({
        arrow: true,
        infinite: true,
        prevArrow: '<button type="button" class="slick-prev"><img src="icons/arr_left.svg"></button>"',
        nextArrow: '<button type="button" class="slick-next"><img src="icons/arr_right.svg"></button>"',
    })
    // const text = readTextFile("../docs/ann_1.txt")
    // const files = readTextFile("../docs/files.txt")
    // console.log(files)
    // document.getElementsByClassName('test_title')[0].innerText = text.split('<title>')[0]
    // document.getElementsByClassName('test_content')[0].innerText = text.split('<title>')[1];

    // const test = document.getElementsByClassName('newsPage_content_item')[0]
    // if(test){
    //     test.addEventListener('click', (e) => {
    //         e.preventDefault()
    //         window.active_item = 1
    //         window.location.replace(window.location.origin + '/index.html')
    //     })
    // }
});

// function readTextFile(file)
// {
//     var rawFile = new XMLHttpRequest();
//     let allText = ''
//     rawFile.open("GET", file, false);
//     rawFile.onreadystatechange = function ()
//     {
//         if(rawFile.readyState === 4)
//         {
//             if(rawFile.status === 200 || rawFile.status == 0)
//             {
//                 allText = rawFile.responseText;
//             }
//         }
//     }
//     rawFile.send(null);
//     return allText
// }

